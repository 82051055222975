import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useUser } from "../lib/UserContext";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import theme from "./theme";
import { useNavigate } from "react-router-dom";
import { User } from "../lib/definitions";

export const Navigation: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [rightButtons, setRightButtons] = useState<React.ReactNode>(null);
  const [leftButtons, setLeftButtons] = useState<React.ReactNode>(null);
  const { isAuthenticated, user } = useUser();

  useEffect(() => {
    setLeftButtons(<BaseButtons user={user} isMobile={isMobile} />);
    if (isAuthenticated) {
      setRightButtons(<AuthenticatedButtons />);
    } else {
      setRightButtons(<UnauthenticatedButtons isMobile={isMobile} />);
    }
  }, [isAuthenticated, user, isMobile]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginX: { xs: 2, md: "10vw" },
        }}
      >
        {isMobile ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <IconButton
                color="inherit"
                onClick={toggleMobileMenu}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Button href="/" color="inherit" sx={{ fontWeight: "bold" }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Earbookify
                </Typography>
              </Button>
            </Box>
            <Box>{rightButtons}</Box>
            <Drawer
              anchor="left"
              open={mobileMenuOpen}
              onClose={toggleMobileMenu}
              PaperProps={{
                sx: {
                  width: 250,
                  backgroundColor: theme.palette.background.paper,
                },
              }}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleMobileMenu}
                onKeyDown={toggleMobileMenu}
              >
                <List>
                  <ListItem component="a" href="/">
                    <ListItemText primary="Home" />
                  </ListItem>
                  <ListItem component="a" href="/upload">
                    <ListItemText primary="Upload" />
                  </ListItem>
                  <ListItem component="a" href="/books">
                    <ListItemText primary="Books" />
                  </ListItem>
                  <ListItem component="a" href="/voices">
                    <ListItemText primary="Voices" />
                  </ListItem>
                  {!user?.SubscriptionIsActive && (
                    <ListItem component="a" href="/subscriptions">
                      <ListItemText primary="Subscriptions" />
                    </ListItem>
                  )}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {leftButtons}
            </Box>
            <Box>{rightButtons}</Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

function BaseButtons({
  user,
  isMobile,
}: {
  user: User | null;
  isMobile: boolean;
}) {
  if (isMobile) return null;

  return (
    <>
      <Button href="/" color="inherit" sx={{ fontWeight: "bold" }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ paddingRight: 4, fontWeight: "bold" }}
        >
          Earbookify
        </Typography>
      </Button>
      <Button href="/upload" color="inherit" sx={{ fontWeight: "bold" }}>
        Upload
      </Button>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ backgroundColor: theme.palette.primary.light }}
      />
      <Button href="/books" color="inherit" sx={{ fontWeight: "bold" }}>
        Books
      </Button>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ backgroundColor: theme.palette.primary.light }}
      />
      <Button href="/voices" color="inherit" sx={{ fontWeight: "bold" }}>
        Voices
      </Button>
      {!user?.SubscriptionIsActive && (
        <>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ backgroundColor: theme.palette.primary.light }}
          />
          <Button
            href="/subscriptions"
            color="inherit"
            sx={{ fontWeight: "bold" }}
          >
            Subscriptions
          </Button>
        </>
      )}
    </>
  );
}

function UnauthenticatedButtons({ isMobile }: { isMobile: boolean }) {
  if (isMobile) {
    return (
      <Button
        href="/login"
        color="inherit"
        sx={{ fontWeight: "bold" }}
        aria-label="Login"
      >
        Login
      </Button>
    );
  }

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Button
        href="/login"
        color="inherit"
        sx={{ fontWeight: "bold" }}
        aria-label="Login"
      >
        Login
      </Button>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ backgroundColor: theme.palette.primary.light }}
      />
      <Button
        href="/register"
        color="inherit"
        sx={{ fontWeight: "bold" }}
        aria-label="Register"
      >
        Register
      </Button>
    </Box>
  );
}

function AuthenticatedButtons() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, refetch, logout } = useUser();
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleClose();
    navigate("/profile");
  };

  const formatNumber = (num: number) => {
    return Intl.NumberFormat("en-US").format(num);
  };

  const logoutAndClose = () => {
    logout();
    handleClose();
    refetch();
    navigate("/");
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={2}>
        {user && user.SubscriptionIsActive && (
          <Stack direction="column" display="flex" alignItems="center" gap={1}>
            <LinearProgress
              variant="determinate"
              color="inherit"
              value={
                (Math.min(user.QuotaUsed, user.QuotaTotal) / user.QuotaTotal) *
                100
              }
              sx={{
                width: "100px",
                backgroundColor: theme.palette.primary.dark,
              }}
              aria-label="Quota progress"
            />
            <Typography fontSize="small">
              {formatNumber(Math.min(user.QuotaUsed, user.QuotaTotal))} /{" "}
              {formatNumber(user.QuotaTotal)}
            </Typography>
          </Stack>
        )}
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ backgroundColor: theme.palette.primary.light }}
        />
        <IconButton
          onClick={handleMenu}
          color="inherit"
          size="large"
          aria-label="User menu"
          aria-controls="user-menu"
          aria-haspopup="true"
        >
          <AccountCircle fontSize="large" />
        </IconButton>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
          <MenuItem onClick={() => navigate("/my-subscription")}>
            My Subscription
          </MenuItem>
          <MenuItem onClick={() => navigate("/orders")}>Orders</MenuItem>
          <MenuItem onClick={logoutAndClose}>Logout</MenuItem>
        </Menu>
      </Box>
    </>
  );
}
