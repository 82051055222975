import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import theme from "../components/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Backend } from "../lib/backend";
import { PaymentOption } from "../lib/definitions";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import IconButtonBar from "../components/IconButtonBar";
import BookStepper from "../components/BookStepper";
import PaymentCards from "../components/PaymentCards";
import { useUser } from "../lib/UserContext";
const backend = new Backend();

const SubscriptionSelection: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<PaymentOption | null>(null);
  const [plans, setPlans] = useState<PaymentOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { uploadID } = useParams<{ uploadID: string }>();
  const { isAuthenticated } = useUser();

  useEffect(() => {
    if (!isAuthenticated) {
      const currentPath = window.location.pathname + window.location.search;
      navigate(`/login?redirect=${encodeURIComponent(currentPath)}`);
      return;
    }

    const fetchPlans = async () => {
      try {
        if (uploadID) {
          const fetchedPlans = await backend.getPaymentOptions(
            Number(uploadID)
          );
          setPlans(fetchedPlans);
        } else {
          setError("No book ID provided");
        }
      } catch (err) {
        setError("Failed to fetch payment options");
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [uploadID, isAuthenticated, navigate]);

  const handleCheckout = () => {
    if (selectedPlan && uploadID) {
      navigate(`/checkout?planType=${selectedPlan.Type}&uploadID=${uploadID}`);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="md" sx={{ paddingBottom: "100px" }}>
      <BookStepper bookId={uploadID || ""} currentStep="payment" />
      <Box textAlign="center" my={4}>
        <Typography variant="h4" gutterBottom>
          Choose Your Plan
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Select the plan that works best for you
        </Typography>
      </Box>
      <PaymentCards plans={plans} onSelect={setSelectedPlan} />
      <IconButtonBar
        price={selectedPlan?.Price ?? null}
        left={
          <Tooltip title="Back">
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
                boxShadow: 1,
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                },
                padding: 2,
              }}
              size="large"
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        }
        middle={null}
        right={
          <Tooltip title="Subscribe">
            <span>
              <IconButton
                onClick={handleCheckout}
                disabled={!selectedPlan || !uploadID}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: theme.palette.action.disabledBackground,
                    color: theme.palette.action.disabled,
                  },
                  padding: 2,
                }}
                size="large"
              >
                <ShoppingCartIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        }
      />
    </Container>
  );
};

export default SubscriptionSelection;
