import Cookies from "js-cookie";

export const revokeCookieConsent = (callback?: () => void) => {
  Cookies.remove("gdpr-consent");
  if (callback) {
    callback();
  } else {
    window.location.reload(); // Reload the page to show the consent bar again
  }
};

export const setCookieConsent = (callback?: () => void) => {
  Cookies.set("gdpr-consent", "true", { expires: 365 });
  if (callback) {
    callback();
  }
};
