import { Box, Container, Typography } from "@mui/material";
import { getContact } from "../lib/contact";

const PrivacyPolicy = (): JSX.Element => {
  const { companyName, address, email, phone, website } = getContact();

  const points = getPoints({
    date: new Date(),
    companyName,
    address,
    email,
    phone,
    website,
  });

  return (
    <Container maxWidth="md">
      <Typography variant="h4" sx={{ mb: 2 }}>
        {points.title}
      </Typography>
      <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
        {points.description}
      </Typography>
      {points.points.map((point) => (
        <Box key={point.title} sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ whiteSpace: "pre-wrap", ml: 2 }}>
            {point.title}
          </Typography>
          <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
            {point.content}
          </Typography>
        </Box>
      ))}
    </Container>
  );
};

export default PrivacyPolicy;

type PrivacyPolicyProps = {
  date: Date;
  companyName: string;
  address: string;
  email: string;
  phone: string;
  website: string;
};

const getPoints = ({
  date,
  companyName,
  address,
  email,
  phone,
  website,
}: PrivacyPolicyProps) => {
  return {
    title: "PRIVACY POLICY",
    description: `
Effective Date: ${date.toLocaleDateString()}	

Welcome to ${companyName}! (“we,” “us,” or “our”). This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our services, including the upload and conversion of ebooks or documents for processing and downloading using our proprietary  artificial intelligence ("AI") technology on our website ${website} (“Site”). By accessing or using our Site and services, you agree to the collection and use of your information in accordance with this Privacy Policy.
`,
    points: [
      {
        title: "1. INFORMATION WE COLLECT",
        content: `
We are committed to safeguarding your personal information. To provide you with our services effectively, we collect various types of information from our users, which may include:
1.1 Personal Information: When you register for our services or interact with our Site, you may be required to provide personal information. This information may include, but is not limited to:

Full Name: Your first and last name to identify you.
Email Address: To communicate with you regarding your account and services.
Contact Information: Such as phone number or mailing address for service-related communication.
Payment Information: If applicable, details required to process transactions securely.
1.2 Non-Personal Information: In addition to personal information, we also collect non-personal information that does not identify you personally. This information may include:

IP Address: To understand your geographic location and enhance user experience.
Browser Type: Information about the web browser you use to access our Site.
Device Information: Details about the device used to access our Site, including the device type and operating system.
Pages Visited: Information regarding the specific pages and features you access on our Site.
Time and Date of Visits: To monitor and analyze usage patterns.
Referring URLs: Information about the website that led you to our Site.

This information is used to improve our services, enhance user experience, and ensure the security of our Site.
`,
      },
      {
        title: "2. USE OF INFORMATION",
        content: `
We may use the information we collect for various purposes, including:

2.1 Service Provision: We use your personal information to provide and enhance the services offered on our platform, including:
Facilitating the uploading, processing, and conversion of your content.
Managing your account, including user authentication and service access.
Processing payments and delivering invoices, if applicable.
2.2 Communication: Your contact information, such as email addresses and phone numbers, may be used to:
Send you important updates, notifications, and confirmations regarding your use of our services.
Respond to any inquiries or customer support requests.
Provide information about new features, services, or promotional offers, unless you choose to opt-out of such communications.
2.3 Site and Service Improvement: We analyze non-personal information to:
Understand how users interact with our Site, services, and features.
Optimize the performance, layout, and usability of the website.
Develop and introduce new features or enhancements based on user behavior.
2.4 Security and Compliance: We may use your information to:
Monitor, detect, and prevent any security breaches or fraudulent activities.
Ensure compliance with our terms, policies, and applicable legal requirements.
Fulfill any legal obligations or respond to lawful requests, including from regulatory authorities.
2.5 Customization and Personalization: Your preferences and usage patterns may be analyzed to:
Personalize your experience by tailoring content, features, and recommendations based on your usage.
Deliver targeted advertisements or offers that align with your interests and preferences, where applicable.
`,
      },
      {
        title: "3. LEGAL BASIS FOR PROCESSING PERSONAL DATA",
        content: `
We process your personal data in accordance with applicable data protection laws, including but not limited to the General Data Protection Regulation (GDPR) and relevant Slovakian privacy regulations. The legal bases on which we rely to process your personal data are as follows:
3.1 Contractual Necessity: We process your personal data to fulfill the terms of the contract between you and ${companyName}, enabling us to provide the services you request. This includes processing the content you upload, managing your account, and delivering our services efficiently and securely.
3.2 Legal Obligation: In certain circumstances, we are required to process your personal data to comply with legal obligations. This may include retaining data for tax, audit, or regulatory purposes, or responding to lawful requests from public authorities or law enforcement agencies.
3.3 Legitimate Interests: We process your personal data when it is necessary to pursue our legitimate interests, provided that those interests are not overridden by your rights and freedoms. These legitimate interests include:
Improving our services and user experience.
Enhancing the security of our platform and protecting it from fraud or unauthorized access.
Conducting marketing activities, including the communication of updates and promotional offers, provided you have not opted out.
3.4 Consent: In cases where we rely on your consent for specific processing activities (such as sending direct marketing communications), we will ensure that your consent is freely given, specific, informed, and unambiguous. You have the right to withdraw your consent at any time without affecting the lawfulness of processing based on consent before its withdrawal.
`,
      },
      {
        title: "4. DISCLOSURE OF INFORMATION",
        content: `
We are committed to safeguarding your personal data and will only disclose your information in the following circumstances:
4.1 Service Providers: We may share your personal data with trusted third-party service providers who assist us in operating our website, delivering services, or conducting our business. These service providers are bound by strict confidentiality agreements and are authorized to process your personal data solely for the purposes of providing services to us.
4.2 Legal Compliance: We may disclose your personal data if required by law, regulation, legal process, or government request. This includes sharing information to:
Comply with applicable laws, regulations, or court orders.
Protect our legal rights, privacy, safety, or property, as well as those of our users or the public.
Respond to lawful requests from public authorities, including to meet national security or law enforcement requirements.
4.3 Business Transfers: In the event of a merger, acquisition, reorganization, or sale of all or a portion of our assets, your personal data may be transferred as part of that transaction. In such cases, we will ensure that the recipient of your data is bound by terms that offer at least the same level of protection as this Privacy Policy.
4.4 Protecting Our Rights: We may disclose your personal data when we believe it is necessary to:
Enforce our terms and conditions or other agreements.
Investigate potential violations of our policies.
Detect, prevent, or address fraud, security, or technical issues.
4.5 With Your Consent: In situations where your consent is required for the disclosure of your personal data, we will obtain your explicit consent before sharing the information with third parties not covered by the above categories.
`,
      },
      {
        title: "5. DATA SECURITY",
        content: `
We take the security of your personal data seriously and are committed to protecting it from unauthorized access, use, disclosure, alteration, or destruction. We implement a variety of administrative, technical, and physical safeguards to ensure the confidentiality, integrity, and availability of your data. These measures include encryption technologies, secure server environments, access controls, and regular security assessments to protect against potential vulnerabilities.
While we strive to protect your personal information, however no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security. You acknowledge and accept that any transmission of personal information is done at your own risk. In the event of a security breach, we will comply with all applicable laws and regulations regarding the notification of affected individuals.
We encourage you to take personal precautions, such as using strong passwords and keeping your login credentials confidential, to help further secure your personal information.
`,
      },
      {
        title: "6. USER RIGHTS",
        content: `
As a user, you have specific rights regarding your personal data under applicable data protection laws. We are committed to respecting and facilitating the exercise of these rights. Depending on your jurisdiction, your rights may include, but are not limited to, the following:

Right to Access: You have the right to request confirmation as to whether or not your personal data is being processed and, if so, access the data we hold about you.
Right to Rectification: You may request the correction of any inaccurate or incomplete personal data we hold about you.
Right to Erasure ("Right to be Forgotten"): Under certain circumstances, you may request the deletion of your personal data, such as when the data is no longer necessary for the purposes for which it was collected or if you withdraw your consent.
Right to Restriction of Processing: You have the right to request the restriction of processing of your personal data if you contest its accuracy, if the processing is unlawful, or if we no longer need the data but you require it for the establishment, exercise, or defense of legal claims.
Right to Data Portability: In certain circumstances, you have the right to request that your personal data be transferred to another data controller in a structured, commonly used, and machine-readable format.
Right to Object: You may object to the processing of your personal data where the processing is based on our legitimate interests or for direct marketing purposes.
Right to Withdraw Consent: If we process your personal data based on your consent, you have the right to withdraw that consent at any time. Please note that withdrawing consent will not affect the lawfulness of processing that took place before the withdrawal.
Right to Lodge a Complaint: If you believe that we have violated your privacy rights, you have the right to lodge a complaint with the relevant data protection authority in your jurisdiction.

To exercise any of your rights, please contact us using the information provided in the "Contact Us" section of this policy. We will respond to your request in accordance with applicable data protection laws. Please note that certain rights may be subject to limitations and conditions, as permitted by law.
`,
      },
      {
        title: "7. THIRD-PARTY LINKS",
        content: `
Our website and services may contain links to third-party websites, applications, or services that are not owned, controlled, or operated by us. These third-party links are provided solely for your convenience and informational purposes. We do not endorse, monitor, or have any control over these external sites, and we are not responsible for the content, privacy policies, or practices of any third-party services.
By accessing third-party links, you acknowledge that you are subject to the terms and conditions, as well as the privacy policies, of those external sites. We strongly encourage you to review the terms and privacy policies of any third-party websites before providing them with your personal information or engaging in their services.
We do not assume any liability for any damages, losses, or issues arising from your interactions with third-party websites or services. Any use of third-party links is at your own risk, and we recommend exercising caution when interacting with such external platforms.
`,
      },
      {
        title: "8. CHANGES TO THIS PRIVACY POLICY",
        content: `
We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes.
`,
      },
      {
        title: "9. CONTACT US",
        content: `
If you have any questions or concerns about this Privacy Policy, please contact us at:

Please write company Name: ${companyName}
Address: ${address}
Email: ${email}
Phone: ${phone}
`,
      },
    ],
  };
};
