import { Box, Container, Typography } from "@mui/material";
import { getContact } from "../lib/contact";

const TermsAndConditions = (): JSX.Element => {
  const { companyName, address, email } = getContact();
  const points = getPoints({
    date: new Date(),
    companyName,
    address,
    email,
  });

  return (
    <Container maxWidth="md">
      <Typography variant="h4" sx={{ mb: 2 }}>
        {points.title}
      </Typography>
      <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
        {points.description}
      </Typography>
      {points.points.map((point) => (
        <Box key={point.title} sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ whiteSpace: "pre-wrap", ml: 2 }}>
            {point.title}
          </Typography>
          <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
            {point.points}
          </Typography>
        </Box>
      ))}
    </Container>
  );
};

export default TermsAndConditions;

type TermsAndConditionsProps = {
  date: Date;
  companyName: string;
  address: string;
  email: string;
};

const getPoints = ({
  date,
  companyName,
  address,
  email,
}: TermsAndConditionsProps) => {
  return {
    title: `TERMS AND CONDITION`,
    description: `
Effective Date: ${date.toLocaleDateString()}

Welcome to ${companyName}! These Terms and Conditions ("Terms") form a legally binding agreement between you ("User" or "you") and ${companyName} ("we," "us," or "our"), governing your access to and use of the services provided by our website, which enables users to upload their own ebooks or documents for processing and downloading using our proprietary  artificial intelligence ("AI") technology. By using our services, you acknowledge and agree to these Terms in their entirety. If you do not agree with any part of these Terms, please discontinue the use of our services immediately.
This document provides comprehensive protection for ${companyName} under the applicable laws of Slovakia, limiting our liability and ensuring that users assume full responsibility for their actions, including the legality of the content they upload.

Please read these Terms carefully, as they contain important information about your legal rights and obligations.
`,
    points: [
      {
        title: "1. ACCEPTANCE OF TERMS",
        points: `
By accessing or using our website and services, you agree to these Terms. If you disagree with these Terms or any part of them, you are prohibited from using the website and services. Your use of our services constitutes your acceptance of these Terms in full.
You must also agree to the Privacy Policy and any additional terms that may apply to specific features of the website or services. Any additional terms will be made available when you access specific features and shall be deemed incorporated into these Terms.
`,
      },
      {
        title: "2. ELIGIBILITY TO USE THE SERVICES",
        points: `
To use our website and services, you must meet the following criteria and comply with relevant Slovak law, including but not limited to the Civil Code of the Slovak Republic, Act No. 40/1964 Coll., and Slovak Intellectual Property Law:

2.1 Age Requirement: You must be at least 18 years old to use the services, as required by Slovak law. By using our website, you represent and warrant that you meet this age requirement. Minors under the age of 18 are prohibited from accessing or using the services.
2.2 Legal Capacity: You must have the legal capacity to enter into a binding agreement. This means you are not subject to any legal incapacity that would limit your ability to accept these Terms, as stipulated under Section 8 of the Slovak Civil Code, which governs the capacity to undertake legal acts.
2.3 Rights to Uploaded Content: You must have the necessary rights, licenses, consents, and permissions to upload, modify, and use the ebook or document on our platform. This includes compliance with Slovak Intellectual Property Law, specifically Act No. 618/2003 Coll. on Copyright and Related Rights, ensuring that your Content does not infringe on any third-party intellectual property rights.
2.4 Compliance with Applicable Laws: You agree to comply with all applicable Slovak laws, including Data Protection Laws, such as Act No. 18/2018 Coll. on the Protection of Personal Data, and any other international legal obligations that may apply to the Content you upload, use, or distribute.

Failure to meet any of the above criteria will result in the immediate termination of your access to the services. Furthermore, you may be held legally responsible under applicable Slovak laws for any violations, including but not limited to intellectual property infringement, unlawful use of the services, or providing false representations regarding your eligibility.
`,
      },
      {
        title: "3. USER RESPONSIBILITIES AND LEGAL REPRESENTATIONS",
        points: `
By using our services, you acknowledge and agree to the following responsibilities and legal obligations:

3.1 Content Ownership and Rights: When uploading any content, including but not limited to ebooks, documents, or other materials (collectively referred to as "Content"), you represent and warrant that:
You are the rightful owner of the Content, or you have obtained all necessary licenses, rights, permissions, and consents from the appropriate third parties to upload, modify, and use the Content on our platform.
The Content you upload does not infringe upon any third party's intellectual property rights, including but not limited to copyrights, trademarks, or patents, and complies with all relevant laws, including Act No. 618/2003 Coll. on Copyright and Related Rights.
You have the legal authority to grant us a limited, non-exclusive right to process the Content for the purpose of creating the narrated audio file, and such processing will not violate any legal restrictions or third-party rights.

3.2 Legality of Content: You further represent and warrant that:
The Content you upload is lawful and does not contain any material that is defamatory, obscene, offensive, or otherwise unlawful under applicable laws, including but not limited to Slovak Act No. 300/2005 Coll. (Criminal Code).
The use of our services to convert the Content into audio does not violate any local, national, or international laws or regulations to which you or the Content are subject.

3.3 Confirmation of Legal Rights: Before uploading any Content, you will be required to confirm, by checking a mandatory checkbox, that you possess the legal rights to use the Content on our platform. Specifically, you will affirm:

“I CONFIRM THAT I HAVE THE LEGAL RIGHTS TO UPLOAD, MODIFY, AND CONVERT THIS EBOOK/DOCUMENT. I UNDERSTAND THAT I WILL BE SOLELY RESPONSIBLE FOR ANY LEGAL CLAIMS, DAMAGES, OR LIABILITIES THAT MAY ARISE FROM MY USE OF THIS SERVICE.”

By checking this box, you accept full responsibility for the Content you upload and for ensuring its compliance with these Terms, and you acknowledge that we bear no liability for any disputes, claims, or legal issues arising from your use of our services.

3.4 Indemnification for Legal Violations: You agree to fully indemnify and hold harmless ${companyName}, its officers, directors, employees, agents, affiliates, and licensors from any claims, damages, liabilities, losses, or expenses (including reasonable attorney fees) that may arise from:
Your violation of these Terms.
Your infringement of any third-party intellectual property or legal rights.
Your unlawful or improper use of the services, including the unlawful upload, modification, or distribution of Content.
By using our services, you understand that you are solely responsible for any legal consequences that may result from your actions, and you agree to assume full liability for any claims made against you or ${companyName}. 
 `,
      },
      {
        title: "4. CONTENT PROCESSING AND USE",
        points: `
By using our services, you acknowledge and agree to the following terms regarding the processing and use of your Content:

4.1 Content Submission and Processing: When you upload Content to our platform, you grant us a non-exclusive, worldwide, royalty-free license to process and store the Content for the purpose of fulfilling your request to use our services.
4.2 Restrictions on Content and Prohibited Use of Services: You are solely responsible for the Content you upload. The Content must not:
Infringe upon the intellectual property rights of any third party.
Contain any unlawful, defamatory, obscene, or otherwise offensive material.
Violate any local, national, or international laws.

You agree that you will not use our services for any of the following purposes:
Uploading Content that you do not have the legal right to modify, distribute, or convert using our services.
Uploading any material that violates any laws, infringes upon intellectual property rights, or includes offensive, defamatory, or unlawful material.
Using the service to harm, harass, or defame any third party or to violate any applicable law, regulation, or code of conduct.
Circumventing or attempting to bypass any security measures on the website.

Violation of any of the above may result in immediate termination of your access to our services and legal action being taken against you.

4.3 User Liability for Uploaded Content: You agree that you are solely responsible for any legal or financial repercussions resulting from the Content you upload, including but not limited to any claims of copyright infringement, defamation, or illegal content. You further agree to indemnify and hold harmless ${companyName} from any claims or damages arising out of your use of the platform or any violations related to the Content you upload.

We reserve the right to refuse or remove any Content that, in our sole discretion, violates these Terms or is otherwise objectionable.
`,
      },
      {
        title: "5. DISCLAIMER OF WARRANTIES",
        points: `
To the fullest extent permitted by applicable law, the services provided by us are offered on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the reliability, accuracy, or availability of our services or the content processed through our platform.

5.1 No Warranty of Specific Results: We does not guarantee that the services will be uninterrupted, error-free, or free of viruses or other harmful components. We do not warrant that any specific results will be achieved through your use of the services.
5.2 No Liability for Third-Party Actions: We are not responsible for any unauthorized use or sharing of the narrated audio files by you or any third party. Once the narrated audio file is downloaded, you assume full responsibility for its use, distribution, and any legal liabilities that may arise.
5.3 No Endorsement: The presence of any third-party links, advertisements, or content within our services does not imply endorsement or approval by us. We are not responsible for the content, accuracy, or opinions expressed in any such third-party materials.
5.4 Compliance with Laws: You acknowledge that it is your responsibility to ensure that your use of the services complies with all applicable laws, regulations, and industry standards. We does not warrant that your use of the services will comply with any legal or regulatory obligations.

By using our services, you agree to the terms outlined in this Disclaimer of Warranties and understand that you are assuming the risks associated with using our platform and processing your Content.
`,
      },
      {
        title: "6. LIMITATION OF LIABILITY",
        points: `
TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING FROM OR RELATED TO YOUR USE OF THE SERVICES PROVIDED ON OUR PLATFORM, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS, DATA, OR BUSINESS OPPORTUNITIES, EVEN IF (WRITE COMPANY NAME PLEASE) HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION APPLIES TO ANY CLAIMS, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICES OR ANY CONTENT UPLOADED OR PROCESSED THROUGH OUR PLATFORM. YOU ACKNOWLEDGE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN RISK AND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY CONSEQUENCES THAT MAY ARISE FROM SUCH USE. FURTHERMORE, ${companyName.toUpperCase()} DOES NOT GUARANTEE THE ACCURACY, RELIABILITY, OR COMPLETENESS OF ANY INFORMATION OR CONTENT PROCESSED THROUGH THE SERVICES, AND YOU AGREE THAT ${companyName.toUpperCase()} SHALL NOT BE HELD LIABLE FOR ANY ERRORS OR OMISSIONS IN SUCH INFORMATION. THIS LIMITATION OF LIABILITY SHALL SURVIVE THE TERMINATION OF THESE TERMS AND YOUR USE OF THE SERVICES.
        `,
      },
      {
        title: "7. INDEMNIFICATION",
        points: `
You agree to indemnify, defend, and hold harmless ${companyName}, its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, losses, liabilities, damages, expenses, or costs, including reasonable attorney fees, arising out of or related to your use of the services, your violation of these Terms, or your infringement of any third-party rights, including but not limited to intellectual property rights or privacy rights. This obligation to indemnify shall apply regardless of whether such claims arise from your actions or omissions or those of any individual or entity that you permit to access or use your account. Furthermore, you agree to cooperate with us in the defence of any such claims. We reserve the right to assume the exclusive defence and control of any matter for which you are required to indemnify us, and you shall not settle any such matter without our prior written consent. This indemnification obligation shall survive the termination of these Terms and your use of the services
`,
      },
      {
        title: "8. TERMINATION OF SERVICES",
        points: `
We reserve the right to terminate or suspend your access to the website and services at our sole discretion, without prior notice, for any violation of these Terms, misuse of the website, or non-compliance with applicable laws.
Termination will not affect any legal obligations or liabilities that may have arisen prior to termination.
`,
      },
      {
        title: "9. PRIVACY",
        points: `
We value your privacy and take appropriate measures to protect your personal data. Please refer to our Privacy Policy for more details on how we collect, use, and store your information. By agreeing to these Terms, you also acknowledge and agree to the terms outlined in the Privacy Policy.
`,
      },
      {
        title: "10. SEVERABILITY",
        points: `
If any provision of these Terms is held to be invalid, illegal, or unenforceable by a court of competent jurisdiction, such provision shall be deemed modified to the extent necessary to make it valid, legal, and enforceable. If no modification can render the provision valid, legal, or enforceable, it shall be severed from these Terms, and the remaining provisions shall continue in full force and effect. The invalidity of any provision shall not affect the validity of the other provisions of these Terms, which shall remain in effect and be enforceable to the fullest extent permitted by law.
`,
      },
      {
        title: "11. DISCLAIMER OF LIABILITY FOR AI-GENERATED CONTENT",
        points: `
You acknowledge that the content generated through our services is created by artificial intelligence algorithms and may not always be accurate, complete, or suitable for your intended purposes. We does not guarantee the quality, accuracy, or legality of any content produced by our AI technology. You agree that we shall not be liable for any claims, damages, or losses resulting from your use of, or reliance on, AI-generated content, including but not limited to any infringement of intellectual property rights, defamation, or violation of applicable laws. By using our services, you assume all risks associated with the use of AI-generated content and agree to indemnify and hold harmless ${companyName} from any claims arising from such use.
`,
      },
      {
        title: "12. GOVERNING LAW",
        points: `
These Terms shall be governed by and construed in accordance with the laws of Slovakia. Any disputes arising out of or related to these Terms shall be exclusively resolved in the courts of Slovakia.
`,
      },
      {
        title: "13. DISPUTE RESOLUTION",
        points: `
13.1 Informal Resolution: In the event of any dispute, claim, or controversy arising out of or relating to these Terms, your use of our services, or any related matters (collectively, "Disputes"), you agree to first attempt to resolve the Disputes amicably through informal discussions with ${companyName}.
13.2 Negotiation: If informal discussions do not resolve the Dispute, the parties agree to engage in good faith negotiations to attempt to resolve the matter.
13.3 Mediation: If the Dispute remains unresolved after negotiation, the parties shall attempt to resolve the Dispute through mediation. Either party may initiate mediation by providing written notice to the other party, and the mediation shall be conducted in accordance with the rules of a recognized mediation organization in Slovakia.
13.4 Arbitration: If the Dispute cannot be resolved through negotiation or mediation within thirty (30) days from the date the Dispute arises, you agree that the Dispute shall be submitted to binding arbitration in accordance with the rules of the Arbitration Court of Slovakia. The arbitration shall be conducted in Slovak, and the seat of arbitration shall be in Trnava, Slovakia. The decision of the arbitrator shall be final and binding upon both parties, and any award may be entered in any court of competent jurisdiction.
13.5 Waiver of Rights: You acknowledge and agree that you are waiving your right to a trial by jury and your right to participate in a class action lawsuit or class-wide arbitration. The parties agree that any proceedings will be conducted only on an individual basis and not in a class, consolidated, or representative action.
13.6 Injunctive Relief: Notwithstanding the foregoing, either party may seek injunctive relief or other equitable remedies in any court of competent jurisdiction to protect its intellectual property rights or confidential information.
        `,
      },
      {
        title: "14. AMENDMENTS",
        points: `
We reserve the right to modify or update these Terms at any time. You will be notified of any changes, and your continued use of the services following such changes constitutes acceptance of the updated Terms.
        `,
      },
      {
        title: "15. CONTACT INFORMATION",
        points: `
If you have any questions, concerns, or inquiries regarding these Terms, please contact us at: Business Address: ${address} or Email: ${email}
`,
      },
    ],
  };
};
