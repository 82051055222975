import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
  Box,
  Container,
  Radio,
  Divider,
  IconButton,
  Tooltip,
  Paper,
} from "@mui/material";
import { Backend } from "../lib/backend";
import { useParams, useNavigate } from "react-router-dom";
import theme from "../components/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { Book } from "../lib/definitions";
import BookCard from "../components/BookCard";
import IconButtonBar from "../components/IconButtonBar";
import OutputFormatSelect, {
  OutputFormat,
} from "../components/OutputFormatSelect";
import BookStepper from "../components/BookStepper";
import { useUser } from "../lib/UserContext";

const backend = new Backend();

const VoiceSelection: React.FC = () => {
  const { bookId } = useParams<{ bookId: string }>();
  const navigate = useNavigate();
  const [voices, setVoices] = useState<string[]>([]);
  const [book, setBook] = useState<Book | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedVoice, setSelectedVoice] = useState<string | null>(null);
  const [outputFormat, setOutputFormat] = useState<OutputFormat>("m4b");
  const { user } = useUser();

  useEffect(() => {
    if (!bookId || isNaN(Number(bookId))) {
      navigate(-1);
      return;
    }

    const fetchData = async () => {
      try {
        const [fetchedVoices, fetchedBook] = await Promise.all([
          backend.listVoices(),
          backend.fetchBook(Number(bookId)),
        ]);
        setVoices(fetchedVoices);
        setBook(fetchedBook);
        if (fetchedBook.VoiceName) {
          setSelectedVoice(fetchedBook.VoiceName);
        }
        if (fetchedBook.OutputFormat) {
          setOutputFormat(fetchedBook.OutputFormat as OutputFormat);
        }
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, [bookId, navigate]);

  const handleNarrate = async () => {
    if (selectedVoice && bookId && book) {
      try {
        await backend.saveVoice(Number(bookId), selectedVoice, outputFormat);

        const totalCharacters = book.Chapters.filter(
          (chapter) => chapter.IsEnabled
        ).reduce((sum, chapter) => sum + chapter.Content.length, 0);

        if (
          user &&
          user.SubscriptionIsActive &&
          totalCharacters <= user.QuotaTotal - user.QuotaUsed
        ) {
          navigate(`/checkout-free/${bookId}`);
        } else {
          navigate(`/subscription/${bookId}`);
        }
      } catch (err) {
        setError("Failed to start narration");
      }
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!book) return <Typography>No book found</Typography>;

  return (
    <Container maxWidth="md" sx={{ paddingBottom: "100px" }}>
      <BookStepper bookId={bookId || ""} currentStep="voice" />
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" gutterBottom>
          Select a Voice
        </Typography>
      </Box>
      <BookCard book={book} />
      <Box my={4} display="flex" justifyContent="center">
        <OutputFormatSelect value={outputFormat} onChange={setOutputFormat} />
      </Box>
      <Paper
        elevation={3}
        sx={{ p: 4, mt: 4, mb: 4, borderRadius: 2, overflow: "hidden" }}
      >
        <List>
          {voices.map((voice, index) => (
            <React.Fragment key={voice}>
              <ListItem
                onClick={() => setSelectedVoice(voice)}
                component="div"
                sx={{
                  cursor: "pointer",
                  alignItems: "center",
                  py: 2,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <Radio
                  checked={selectedVoice === voice}
                  onChange={() => setSelectedVoice(voice)}
                  sx={{
                    color: theme.palette.primary.main,
                    "&.Mui-checked": {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
                <ListItemText
                  primary={
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {voice}
                    </Typography>
                  }
                  sx={{ cursor: "pointer", flex: "1 1 auto" }}
                />
                <audio
                  controls
                  src={`${backend.getBaseUrl()}/voices/${voice}.wav`}
                />
              </ListItem>
              {index < voices.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
      <IconButtonBar
        price={book.Price}
        left={
          <Tooltip title="Back">
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
                boxShadow: 1,
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                },
                padding: 2,
              }}
              size="large"
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        }
        middle={null}
        right={
          <Tooltip title="Narrate">
            <span>
              <IconButton
                onClick={handleNarrate}
                disabled={!selectedVoice}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: theme.palette.action.disabledBackground,
                    color: theme.palette.action.disabled,
                  },
                  padding: 2,
                }}
                size="large"
              >
                <RecordVoiceOverIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        }
      />
    </Container>
  );
};

export default VoiceSelection;
