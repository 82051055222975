import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import SchoolIcon from "@mui/icons-material/School";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import theme from "../components/theme";

const features = [
  {
    icon: <AutoStoriesIcon fontSize="large" />,
    title: "Convert Books to Audio",
    description:
      "Transform your favorite books into high-quality audiobooks with just a few clicks.",
  },
  {
    icon: <SchoolIcon fontSize="large" />,
    title: "Learning Benefits",
    description:
      "Reading to children has been shown to improve their language skills, vocabulary, and comprehension.",
  },
  {
    icon: <RecordVoiceOverIcon fontSize="large" />,
    title: "Multiple Voices",
    description:
      "Choose from a variety of AI-powered voices to narrate your books.",
  },
];

const steps = [
  {
    number: "1",
    title: "Upload Your Ebook",
    description:
      "Simply upload your ebook in PDF or EPUB format to get started.",
  },
  {
    number: "2",
    title: "Customize Chapters",
    description: "Review and adjust chapter divisions to your preference.",
  },
  {
    number: "3",
    title: "Select Voice",
    description: "Choose from our collection of natural-sounding AI voices.",
  },
  {
    number: "4",
    title: "Processing",
    description: "Wait a few minutes while we convert your book to audio.",
  },
  {
    number: "5",
    title: "Listen & Enjoy",
    description: "Your audiobook is ready! Listen on any device, anytime.",
  },
];

const Home: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          py: 8,
          mb: 6,
          boxShadow: 2,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                textAlign="center"
                color={theme.palette.primary.dark}
                sx={{ fontWeight: "bold" }}
              >
                Transform Your Books into Audio
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                sx={{ mb: 3, color: theme.palette.text.secondary }}
              >
                Earbookify uses advanced AI technology to convert your ebooks
                into natural-sounding audiobooks. Simply upload your book,
                customize the chapters, choose a voice, and start listening.
              </Typography>
              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 2, fontWeight: "medium" }}
                >
                  ✓ High-quality AI narration
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 2, fontWeight: "medium" }}
                >
                  ✓ Multiple voice options
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 2, fontWeight: "medium" }}
                >
                  ✓ Chapter customization
                </Typography>
              </Box>
              <Button
                variant="contained"
                size="large"
                component={RouterLink}
                to="/upload"
                sx={{
                  mt: 2,
                  px: 4,
                  py: 1.5,
                  borderRadius: 2,
                  fontSize: "1.1rem",
                }}
              >
                Get Started
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/audiobook.jpg"
                alt="Audiobook illustration"
                sx={{
                  width: "100%",
                  maxWidth: 500,
                  height: "auto",
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ my: 8, py: 4 }}>
        <Typography variant="h3" component="h2" textAlign="center" gutterBottom>
          Our Approach Explained
        </Typography>
        <Box sx={{ position: "relative", mt: 8 }}>
          {/* Connection line */}
          <Box
            sx={{
              position: "absolute",
              top: "40px",
              left: "50px",
              right: "50px",
              height: "2px",
              bgcolor: theme.palette.primary.light,
              display: { xs: "none", md: "block" },
              zIndex: 0,
            }}
          />
          <Grid container spacing={4} justifyContent="center" paddingTop={3}>
            {steps.map((step, index) => (
              <Grid item xs={12} sm={6} md={2.4} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: 80,
                      height: 80,
                      bgcolor: theme.palette.background.paper,
                      borderRadius: "50%",
                      border: `3px solid ${theme.palette.primary.main}`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 2,
                      zIndex: 1,
                      boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      }}
                    >
                      {step.number}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h6"
                    component="h3"
                    gutterBottom
                    textAlign="center"
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {step.title}
                  </Typography>
                  <Typography
                    textAlign="center"
                    color="text.secondary"
                    sx={{
                      maxWidth: "200px",
                      mx: "auto",
                      lineHeight: 1.6,
                    }}
                  >
                    {step.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ my: 8 }}>
        <Typography variant="h3" component="h2" textAlign="center" gutterBottom>
          Our Features
        </Typography>
        <Grid container spacing={4} justifyContent="center" paddingTop={3}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                  {feature.icon}
                  <Typography variant="h5" component="h3" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography>{feature.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Call to Action */}
      <Box
        sx={{
          bgcolor: theme.palette.primary.main,
          color: "white",
          py: 8,
          borderRadius: 2,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            component="h2"
            textAlign="center"
            gutterBottom
          >
            Ready to start your audiobook journey?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Button
              variant="contained"
              size="large"
              component={RouterLink}
              to="/register"
              sx={{
                bgcolor: "white",
                color: "primary.main",
                "&:hover": {
                  bgcolor: "grey.100",
                },
              }}
            >
              Sign Up Now
            </Button>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Home;
