import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Box,
  CircularProgress,
  Paper,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  AlertProps,
} from "@mui/material";
import { Backend } from "../lib/backend";
import { Chapter } from "../lib/definitions";
import IconButtonBar from "../components/IconButtonBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import theme from "../components/theme";
import BookStepper from "../components/BookStepper";
import CustomSnackbar from "../components/CustomSnackbar";

const backend = new Backend();

const ChapterPreview: React.FC = () => {
  const { bookId, chapterId } = useParams<{
    bookId: string;
    chapterId: string;
  }>();
  const navigate = useNavigate();
  const [chapter, setChapter] = useState<Chapter | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertProps["severity"]>("success");

  useEffect(() => {
    const fetchData = async () => {
      if (!bookId || !chapterId) {
        setError("Missing book ID or chapter ID");
        setLoading(false);
        return;
      }

      try {
        const fetchedChapter = await backend.fetchChapter(Number(chapterId));
        if (fetchedChapter) {
          setChapter(fetchedChapter);
        } else {
          setError("Chapter not found");
        }
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [bookId, chapterId]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (chapter) {
      setChapter({ ...chapter, Title: event.target.value });
    }
  };

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (chapter) {
      setChapter({ ...chapter, Content: event.target.value });
    }
  };

  const handleIsEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (chapter) {
      setChapter({ ...chapter, IsEnabled: event.target.checked });
    }
  };

  const handleSave = async () => {
    if (chapter) {
      try {
        await backend.updateChapter(chapter);
        setSnackbarOpen(true);
        setSnackbarMessage("Chapter saved successfully");
        setSnackbarSeverity("success");
      } catch (err) {
        setSnackbarOpen(true);
        setSnackbarMessage("Failed to save changes");
        setSnackbarSeverity("error");
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!chapter) return <Typography>No chapter found</Typography>;

  return (
    <Container maxWidth="md" sx={{ mb: 10 }}>
      <BookStepper bookId={bookId || ""} currentStep="book" />
      <Typography variant="h4" gutterBottom padding={3} textAlign="center">
        Chapter Preview
      </Typography>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <FormControlLabel
            control={
              <Switch
                checked={chapter.IsEnabled}
                onChange={handleIsEnabledChange}
                sx={{
                  color: theme.palette.primary.main,
                  "&.Mui-checked": {
                    color: theme.palette.primary.main,
                  },
                  mx: 4,
                }}
              />
            }
            label="Enabled"
            labelPlacement="bottom"
          />
          <TextField
            fullWidth
            label="Chapter Title"
            value={chapter.Title}
            onChange={handleTitleChange}
            margin="normal"
            sx={{ flexGrow: 1, mr: 2 }}
          />
        </Box>
        <TextField
          fullWidth
          label="Chapter Content"
          multiline
          rows={20}
          value={chapter.Content}
          onChange={handleContentChange}
          margin="normal"
        />
      </Paper>
      <IconButtonBar
        price={null}
        left={
          <Tooltip title="Back to Book">
            <IconButton
              onClick={() => navigate(`/book/${bookId}`)}
              sx={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
                boxShadow: 1,
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                },
                padding: 2,
              }}
              size="large"
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        }
        middle={null}
        right={
          <Tooltip title="Save Changes">
            <IconButton
              onClick={handleSave}
              sx={{
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                padding: 2,
              }}
              size="large"
            >
              <SaveIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        }
      />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Container>
  );
};

export default ChapterPreview;
