import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import theme from "./theme";

export const CookieConsentBar: React.FC = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="gdpr-consent"
      style={{
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.background.paper,
        position: "fixed",
        right: "20px",
        left: "auto",
        maxWidth: "400px",
        padding: "10px 20px",
        marginBottom: "140px",
        borderRadius: "5px",
        boxShadow: "0 0 10px rgba(0,0,0,0.2)",
      }}
      buttonStyle={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        fontSize: "13px",
        borderRadius: "3px",
        padding: "5px 10px",
      }}
      expires={150}
    >
      This website uses cookies to enhance the user experience. See our{" "}
      <Link
        to="/privacy-policy"
        style={{
          color: theme.palette.primary.main,
          textDecoration: "underline",
          fontWeight: "bold",
        }}
      >
        Privacy Policy
      </Link>{" "}
      for more details.
    </CookieConsent>
  );
};
