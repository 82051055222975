import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Box,
  CircularProgress,
  Button,
  Container,
  Paper,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Backend } from "../lib/backend";
import BookStepper from "../components/BookStepper";
import { Book } from "../lib/definitions";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const backend = new Backend();

const FreeCheckout: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [book, setBook] = useState<Book | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { uploadID } = useParams<{ uploadID: string }>();

  useEffect(() => {
    const initiateFreeCheckout = async () => {
      if (!uploadID) {
        setError("Missing required parameters");
        setLoading(false);
        return;
      }

      try {
        const book = await backend.fetchBook(Number(uploadID));
        setBook(book);
      } catch (err) {
        console.error("Free checkout error:", err);
        setError("Failed to initiate free checkout. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    initiateFreeCheckout();
  }, [location.search, uploadID]);

  const handleConfirm = async () => {
    if (!book) return;

    try {
      // Here you would typically call an API to confirm the free narration
      // For now, we'll just navigate to a success page
      await backend.narrateBook(book.ID);
      navigate("/books");
    } catch (error) {
      console.error("Confirmation error:", error);
      setError("Failed to confirm free narration. Please try again.");
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 4, mt: 4, textAlign: "center" }}>
          <Typography variant="h5" color="error" gutterBottom>
            {error}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            sx={{ mt: 2 }}
          >
            Go Back
          </Button>
        </Paper>
      </Container>
    );
  }

  if (!book) {
    return null;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
      <BookStepper bookId={uploadID || ""} currentStep="payment" />
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 2,
          bgcolor: theme.palette.background.default,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ mb: 4, color: theme.palette.primary.main }}
        >
          Confirm Free Narration
        </Typography>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                bgcolor: theme.palette.background.paper,
                borderRadius: 4,
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <ShoppingCartIcon sx={{ mr: 1 }} />
                Order Summary
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <List disablePadding>
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        <LibraryBooksIcon
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        Book
                      </Typography>
                    }
                    secondary={book.Title}
                  />
                  <Typography variant="body2">{book.Author}</Typography>
                </ListItem>
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        <AudiotrackIcon
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        Output Format
                      </Typography>
                    }
                  />
                  <Typography variant="body2">{book.OutputFormat}</Typography>
                </ListItem>
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        <RecordVoiceOverIcon
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        Voice
                      </Typography>
                    }
                  />
                  <Typography variant="body2">
                    {book.VoiceName || "Not selected"}
                  </Typography>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                bgcolor: theme.palette.background.paper,
                borderRadius: 4,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" gutterBottom align="center">
                Ready to Create Your Audiobook
              </Typography>
              <Typography variant="body1" paragraph align="center">
                You have enough free credits to narrate this book. Click the
                button below to confirm and start the narration process.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleConfirm}
                sx={{ mt: 2 }}
              >
                Confirm Free Narration
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default FreeCheckout;
