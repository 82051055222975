import React from "react";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../lib/UserContext";

interface GoogleOAuthButtonProps {
  redirectTo: string;
}

const GoogleOAuthButton: React.FC<GoogleOAuthButtonProps> = ({
  redirectTo,
}) => {
  const { googleOAuth } = useUser();
  const navigate = useNavigate();

  const handleSuccess = async (response: CredentialResponse) => {
    if (response.credential) {
      await googleOAuth(response.credential);
      navigate(redirectTo);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
      <GoogleLogin
        useOneTap
        onSuccess={handleSuccess}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </Box>
  );
};

export default GoogleOAuthButton;
