import React, { useState, useEffect } from "react";
import { Backend } from "../lib/backend";
import { Book } from "../lib/definitions";
import {
  Typography,
  Box,
  Button,
  Stack,
  CircularProgress,
  Container,
} from "@mui/material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import DownloadIcon from "@mui/icons-material/Download";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import BookCard from "../components/BookCard";

const backend = new Backend();

const BasicButton: React.FC<{
  onClick: () => void;
  icon: React.ReactNode;
  text: string;
}> = ({ onClick, icon, text }) => {
  return (
    <Button variant="outlined" color="error" startIcon={icon} onClick={onClick}>
      {text}
    </Button>
  );
};

const BookList: React.FC = () => {
  const [books, setBooks] = useState<Book[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const fetchedBooks = await backend.listBooks();
        setBooks(fetchedBooks);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch books");
        setLoading(false);
      }
    };

    fetchBooks();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleDelete = async (bookId: number) => {
    try {
      await backend.deleteBook(bookId);
      // Remove the book from the local state
      setBooks(books.filter((book) => book.ID !== bookId));
    } catch (error) {
      console.error("Error deleting book:", error);
      // Optionally, you can show an error message to the user here
    }
  };

  const renderBookActions = (book: Book) => {
    if (book.Status.toLowerCase() === "in progress") {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress size={24} color="primary" />
        </Box>
      );
    }

    const deleteButton = (
      <BasicButton
        onClick={() => handleDelete(book.ID)}
        icon={<DeleteIcon />}
        text="Delete"
      />
    );

    switch (book.Status.toLowerCase()) {
      case "done":
        return (
          <Stack direction="column" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              href={book.ResultSrc}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </Button>
          </Stack>
        );
      case "pending":
        return (
          <Stack direction="column" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RecordVoiceOverIcon />}
              component={Link}
              to={`/book/${book.ID}`}
            >
              Narrate
            </Button>
            {deleteButton}
          </Stack>
        );
      default:
        return (
          <Stack direction="column" spacing={2}>
            {deleteButton}
          </Stack>
        );
    }
  };

  return (
    <Container maxWidth="md">
      <Typography
        variant="h4"
        component="h1"
        textAlign="center"
        gutterBottom
        sx={{ mt: 4, mb: 6 }}
      >
        Your Books
      </Typography>
      {books.map((book) => (
        <BookCard
          key={book.ID}
          book={book}
          buttons={renderBookActions(book)}
          width={120}
          height={140}
        />
      ))}
    </Container>
  );
};

export default BookList;
