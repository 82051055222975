import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  CircularProgress,
  Button,
  Container,
  Paper,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  Chip,
  useTheme,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Backend } from "../lib/backend";
import BookStepper from "../components/BookStepper";
import { Book, PaymentOption } from "../lib/definitions";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
const backend = new Backend();

const Checkout: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [book, setBook] = useState<Book | null>(null);
  const [plan, setPlan] = useState<PaymentOption | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const searchParams = new URLSearchParams(location.search);
  const planType = searchParams.get("planType");
  const uploadID = searchParams.get("uploadID");

  useEffect(() => {
    const initiateCheckout = async () => {
      if (!planType || !uploadID) {
        setError("Missing required parameters");
        setLoading(false);
        return;
      }

      try {
        const [fetchedBook, fetchedPlans, checkoutSession] = await Promise.all([
          backend.fetchBook(Number(uploadID)),
          backend.getPaymentOptions(Number(uploadID)),
          backend.createCheckoutSession(planType, Number(uploadID)),
        ]);

        setBook(fetchedBook);
        setPlan(fetchedPlans.find((p) => p.Type === planType) || null);
        setClientSecret(checkoutSession.clientSecret);
      } catch (err) {
        console.error("Checkout error:", err);
        setError("Failed to initiate checkout. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    initiateCheckout();
  }, [location.search, planType, uploadID]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 4, mt: 4, textAlign: "center" }}>
          <Typography variant="h5" color="error" gutterBottom>
            {error}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            sx={{ mt: 2 }}
          >
            Go Back
          </Button>
        </Paper>
      </Container>
    );
  }

  if (!clientSecret || !book || !plan) {
    return null;
  }

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
      <BookStepper bookId={uploadID || ""} currentStep="payment" />
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 2,
          bgcolor: theme.palette.background.default,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ mb: 4, color: theme.palette.primary.main }}
        >
          Complete Your Purchase
        </Typography>
        <Grid container spacing={3} padding={6}>
          <Grid item xs={12} md={5}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                bgcolor: theme.palette.background.paper,
                borderRadius: 4,
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <ShoppingCartIcon sx={{ mr: 1 }} />
                Order Summary
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <List disablePadding>
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        <LibraryBooksIcon
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        Book
                      </Typography>
                    }
                    secondary={book.Title}
                  />
                  <Typography variant="body2">{book.Author}</Typography>
                </ListItem>
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        <ShoppingCartIcon
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        Plan
                      </Typography>
                    }
                    secondary={plan.Name}
                  />
                  <Chip label={plan.Price} color="primary" />
                </ListItem>
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        <AudiotrackIcon
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        Output Format
                      </Typography>
                    }
                  />
                  <Typography variant="body2">{book.OutputFormat}</Typography>
                </ListItem>
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        <RecordVoiceOverIcon
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        Voice
                      </Typography>
                    }
                  />
                  <Typography variant="body2">
                    {book.VoiceName || "Not selected"}
                  </Typography>
                </ListItem>
              </List>
              <Divider sx={{ my: 2 }} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Total:</Typography>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {plan.Price}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Your audiobook will be available for download once the payment
                is processed.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={7}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                bgcolor: theme.palette.background.paper,
                height: "100%",
                borderRadius: 4,
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Payment Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Checkout;
