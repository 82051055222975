import React from "react";
import { Stepper, Step, StepButton, Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface BookStepperProps {
  bookId: string;
  currentStep: "book" | "voice" | "payment";
}

const steps = [
  { label: "Book Preview", path: "/book" },
  { label: "Voice Selection", path: "/voice" },
  { label: "Checkout", path: "/subscription" },
];

const BookStepper: React.FC<BookStepperProps> = ({ bookId, currentStep }) => {
  const navigate = useNavigate();
  const currentStepIndex = (() => {
    switch (currentStep) {
      case "book":
        return 0;
      case "voice":
        return 1;
      case "payment":
        return 2;
      default:
        return 0;
    }
  })();

  const handleStep = (index: number) => {
    navigate(`${steps[index].path}/${bookId}`);
  };

  return (
    <Box sx={{ width: "100%", mb: 4, mt: 2 }}>
      <Stepper activeStep={currentStepIndex} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={step.label} disabled={false}>
            <Tooltip title={step.label} enterDelay={500} leaveDelay={200}>
              <StepButton onClick={() => handleStep(index)}>
                {step.label}
              </StepButton>
            </Tooltip>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default BookStepper;
