import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Typography,
  Box,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Link,
  Container,
  LinearProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Backend } from "../lib/backend";
import { useNavigate } from "react-router-dom";
import theme from "./theme";

interface UploadProps {
  onUploadStart: () => void;
  onUploadComplete: (success: boolean, uploadID: number) => void;
}

const backend = new Backend();

const Upload: React.FC<UploadProps> = ({ onUploadStart, onUploadComplete }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const onDrop = async (acceptedFiles: File[]) => {
    if (!termsAccepted) {
      setError("Please accept the terms and conditions before uploading.");
      return;
    }

    if (acceptedFiles.length > 0) {
      setUploading(true);
      setError(null);
      onUploadStart();

      try {
        // Simulating upload progress
        for (let i = 0; i <= 100; i += 10) {
          await new Promise((resolve) => setTimeout(resolve, 200));
          setProgress(i);
        }

        const uploadedBook = await backend.uploadFile(acceptedFiles[0]);
        onUploadComplete(true, uploadedBook.ID);
      } catch (err) {
        console.error("Upload error:", err);
        setError("Failed to upload the book. Please try again.");
        onUploadComplete(false, 0);
      } finally {
        setUploading(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: uploading,
    accept: {
      "application/pdf": [".pdf"],
      "application/epub+zip": [".epub"],
    },
  });

  return (
    <Box sx={{ textAlign: "center", mt: 4 }}>
      <Box
        {...getRootProps()}
        sx={{
          border: `2px dashed ${
            termsAccepted
              ? theme.palette.primary.main
              : theme.palette.action.disabled
          }`,
          borderRadius: 2,
          p: 4,
          cursor: termsAccepted ? "pointer" : "not-allowed",
          backgroundColor:
            isDragActive && termsAccepted
              ? theme.palette.action.hover
              : termsAccepted
              ? "transparent"
              : theme.palette.action.disabledBackground,
          opacity: termsAccepted ? 1 : 0.5,
          transition: "all 0.3s ease",
        }}
      >
        <input {...getInputProps()} disabled={!termsAccepted} />
        {uploading ? (
          <Box>
            <Typography gutterBottom>Uploading...</Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ mt: 2 }}
            />
          </Box>
        ) : isDragActive ? (
          <Typography>Drop the file here ...</Typography>
        ) : (
          <Typography>
            Drag 'n' drop a file here, or click to select a file
          </Typography>
        )}
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Box>

      <Container maxWidth="md">
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              color="primary"
              sx={{
                "&.Mui-checked": {
                  color: theme.palette.primary.main,
                },
              }}
            />
          }
          label={
            <Box>
              <Typography variant="body2" component="span">
                I confirm that I have the legal rights to upload, modify, and
                convert this ebook. I understand that I will be solely
                responsible for any legal claims, damages, or liabilities that
                may arise from my use of this service. I accept the{" "}
                <Link
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions
                </Link>{" "}
                and{" "}
                <Link
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy
                </Link>
              </Typography>
            </Box>
          }
          sx={{
            alignItems: "flex-start",
            marginTop: 2,
            marginLeft: 0,
            "& .MuiFormControlLabel-label": {
              fontSize: "0.875rem",
            },
          }}
        />
      </Container>
    </Box>
  );
};

export default Upload;
