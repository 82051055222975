import React, { useEffect, useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import theme from "./theme";

interface IconButtonBarProps {
  left: React.ReactNode;
  middle: React.ReactNode;
  right: React.ReactNode;
  price: string | null;
}

const IconButtonBar: React.FC<IconButtonBarProps> = ({
  left,
  middle,
  right,
  price,
}) => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      setIsAtBottom(scrollY + windowHeight >= documentHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: isAtBottom ? "50px" : 0,
        left: 0,
        right: 0,
        padding: 1,
        backgroundColor: "background.paper",
        zIndex: 1000,
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "md",
          margin: "0 auto",
          position: "relative", // Add this for absolute positioning of the middle button
        }}
      >
        <Box>{left}</Box>
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {middle}
        </Box>
        <Box display="flex" alignItems="center">
          {price && (
            <Paper
              elevation={3}
              sx={{
                padding: "8px 16px",
                borderRadius: 20,
                backgroundColor: theme.palette.primary.main,
                marginRight: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontWeight: "bold",
                }}
              >
                {price}
              </Typography>
            </Paper>
          )}
          {right}
        </Box>
      </Box>
    </Box>
  );
};

export default IconButtonBar;
