type Contact = {
  companyName: string;
  address: string;
  email: string;
  phone: string;
  website: string;
};

export const getContact = (): Contact => {
  return {
    companyName: process.env.REACT_APP_COMPANY_NAME || "",
    address: process.env.REACT_APP_ADDRESS || "",
    email: process.env.REACT_APP_EMAIL || "",
    phone: process.env.REACT_APP_PHONE || "",
    website: process.env.REACT_APP_WEBSITE || "",
  };
};
