import React, { useState } from "react";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  MenuItem,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useUser } from "../lib/UserContext";
import { Backend } from "../lib/backend";

const backend = new Backend();

type MessageType = "general" | "technical" | "billing" | "claim" | "feature";

interface ContactFormData {
  subject: string;
  messageType: MessageType;
  message: string;
}

const messageTypes = [
  { value: "general", label: "General Inquiry" },
  { value: "technical", label: "Technical Support" },
  { value: "billing", label: "Billing Question" },
  { value: "claim", label: "File a Claim" },
  { value: "feature", label: "Feature Request" },
];

const ContactForm: React.FC = () => {
  const { user, isAuthenticated } = useUser();
  const [formData, setFormData] = useState<ContactFormData>({
    subject: "",
    messageType: "general",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await backend.submitContactForm({
        ...formData,
        email: user?.Email || "",
        name: user ? `${user.FirstName} ${user.LastName}` : "",
      });
      setSuccess(true);
      setFormData({
        subject: "",
        messageType: "general",
        message: "",
      });
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "Failed to submit form. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center">
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom align="center" sx={{ mb: 4 }}>
          We're here to help! Please fill out the form below and we'll get back
          to you as soon as possible.
        </Typography>

        {!isAuthenticated && (
          <Alert severity="info" sx={{ mb: 3 }}>
            Note: Logging in will automatically fill in your contact
            information.
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 3 }}>
            Your message has been sent successfully! We'll get back to you soon.
          </Alert>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            required
            fullWidth
            label="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />

          <TextField
            required
            fullWidth
            select
            label="Message Type"
            name="messageType"
            value={formData.messageType}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          >
            {messageTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            required
            fullWidth
            multiline
            rows={6}
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            sx={{ mb: 3 }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            disabled={loading || !formData.subject || !formData.message}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : "Send Message"}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ContactForm;
