import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
  Box,
  Container,
  Divider,
  Paper,
} from "@mui/material";
import { Backend } from "../lib/backend";

const backend = new Backend();

const VoiceList: React.FC = () => {
  const [voices, setVoices] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const fetchedVoices = await backend.listVoices();
        setVoices(fetchedVoices);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch voices");
        setLoading(false);
      }
    };

    fetchVoices();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="md">
      <Box textAlign="center" mb={4} padding={4}>
        <Typography variant="h4" gutterBottom>
          Available Voices
        </Typography>
      </Box>
      <Paper
        elevation={3}
        sx={{ p: 4, mt: 4, mb: 4, borderRadius: 2, overflow: "hidden" }}
      >
        <List>
          {voices.map((voice, index) => (
            <React.Fragment key={voice}>
              <ListItem
                sx={{
                  py: 2,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {voice}
                    </Typography>
                  }
                />
                <audio
                  controls
                  src={`${backend.getBaseUrl()}/voices/${voice}.wav`}
                />
              </ListItem>
              {index < voices.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default VoiceList;
