import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Book } from "../lib/definitions";

interface BookCardProps {
  book: Book;
  buttons?: React.ReactNode;
  width?: number;
  height?: number;
}

export default function BookCard({
  book,
  buttons,
  width = 120,
  height = 140,
}: BookCardProps) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ display: "flex", mb: 4, p: 2, borderRadius: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CardMedia
            component="img"
            sx={{
              width: width,
              height: height,
              objectFit: "contain",
            }}
            image={book.CoverSrc}
            alt={`Cover for ${book.Title}`}
          />
        </Grid>
        <Grid item xs>
          <CardContent sx={{ flex: "1 0 auto", py: 1 }}>
            <Typography variant="h5" component="div">
              {book.Title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              by {book.Author}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              ISBN: {book.ISBN}
            </Typography>
            {book.Summary.length > 150 && (
              <IconButton onClick={toggleExpanded} sx={{ mt: 1, p: 0 }}>
                {expanded ? (
                  <ExpandLessIcon fontSize="large" />
                ) : (
                  <ExpandMoreIcon fontSize="large" />
                )}
              </IconButton>
            )}
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: expanded ? "unset" : 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: expanded ? "unset" : "ellipsis",
              }}
            >
              {book.Summary}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {buttons}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
