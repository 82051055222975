import React, { useState } from "react";
import { PaymentOption } from "../lib/definitions";
import {
  Card,
  CardContent,
  Typography,
  Grid2,
  Box,
  Tooltip,
} from "@mui/material";
import theme from "./theme";

interface PaymentCardsProps {
  plans: (PaymentOption & { component?: React.ReactNode })[];
  onSelect: (plan: PaymentOption) => void;
}

const PaymentCards: React.FC<PaymentCardsProps> = ({ plans, onSelect }) => {
  const formatDescription = (description: string) => {
    return description.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const [selectedPlan, setSelectedPlan] = useState<PaymentOption | null>(null);

  const handleSelect = (plan: PaymentOption) => {
    if (!plan.IsDisabled) {
      setSelectedPlan(plan);
      onSelect(plan);
    }
  };

  return (
    <Grid2 container justifyContent="center" spacing={6}>
      {plans.map((plan) => (
        <Grid2 size={4} key={plan.Type}>
          <Tooltip title={plan.IsDisabled ? plan.Tooltip : ""} arrow>
            <Card
              raised={selectedPlan?.Type === plan.Type}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition: "all 0.3s ease-in-out",
                cursor: plan.IsDisabled ? "not-allowed" : "pointer",
                minHeight: "300px",
                "&:hover": {
                  transform: plan.IsDisabled ? "none" : "translateY(-5px)",
                  boxShadow: plan.IsDisabled ? 1 : 6,
                },
                border:
                  selectedPlan?.Type === plan.Type
                    ? `2px solid ${theme.palette.primary.main}`
                    : "none",
                opacity: plan.IsDisabled ? 0.5 : 1,
              }}
              onClick={() => handleSelect(plan)}
            >
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  padding: 3,
                }}
              >
                <Box>
                  <Typography variant="h5" component="div" gutterBottom>
                    {plan.Name}
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary"
                    sx={{ textAlign: "center", my: 2 }}
                    gutterBottom
                  >
                    {plan.Price}
                  </Typography>
                </Box>
                <Typography variant="body1" color="text.secondary">
                  {formatDescription(plan.Description)}
                </Typography>
              </CardContent>
            </Card>
          </Tooltip>
        </Grid2>
      ))}
    </Grid2>
  );
};

export default PaymentCards;
