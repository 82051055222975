import React, { useState, useEffect } from "react";
import { useUser } from "../lib/UserContext";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Avatar,
  Switch,
  FormControlLabel,
  Alert,
  Divider,
} from "@mui/material";
import { Backend } from "../lib/backend";
import { revokeCookieConsent, setCookieConsent } from "../utils/cookieConsent";
import SaveIcon from "@mui/icons-material/Save";
import SecurityIcon from "@mui/icons-material/Security";

const backend = new Backend();

export const ProfilePage: React.FC = () => {
  const { user, refetch } = useUser();
  const [formData, setFormData] = useState({
    firstName: user?.FirstName || "",
    lastName: user?.LastName || "",
    email: user?.Email || "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [gdprConsent, setGdprConsent] = useState(true);

  useEffect(() => {
    const consentCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("gdpr-consent="));
    setGdprConsent(!!consentCookie);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (formData.password && formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await backend.updateUserProfile({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password || undefined,
      });
      setSuccess("Profile updated successfully");
      setFormData((prev) => ({
        ...prev,
        password: "",
        confirmPassword: "",
      }));
      refetch();
    } catch (err) {
      setError("Failed to update profile. Please try again.");
    }
  };

  const handleGdprConsentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newConsentValue = event.target.checked;
    backend.updateGdprConsent(newConsentValue);
    setGdprConsent(newConsentValue);
    if (!newConsentValue) {
      revokeCookieConsent();
    } else {
      setCookieConsent();
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper
        elevation={0}
        sx={{ p: 4, borderRadius: 2, bgcolor: "background.default" }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" mb={6}>
          <Avatar
            sx={{
              width: 120,
              height: 120,
              fontSize: 48,
              mb: 2,
              bgcolor: "primary.main",
              boxShadow: 2,
            }}
          >
            {user?.FirstName?.[0]}
            {user?.LastName?.[0]}
          </Avatar>
          <Typography
            variant="h4"
            sx={{ mb: 1 }}
          >{`${user?.FirstName} ${user?.LastName}`}</Typography>
          <Typography variant="body1" color="text.secondary">
            {user?.Email}
          </Typography>
        </Box>

        {(error || success) && (
          <Box mb={4}>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
          </Box>
        )}

        <Box component="form" onSubmit={handleSubmit}>
          <Paper elevation={1} sx={{ p: 4, mb: 4 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
              Profile Settings
            </Typography>

            <Box sx={{ display: "grid", gap: 3, maxWidth: "md" }}>
              <Box
                sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}
              >
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </Box>

              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />

              <Divider sx={{ my: 2 }} />

              <TextField
                fullWidth
                label="New Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                helperText="Leave blank to keep current password"
              />

              <TextField
                fullWidth
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </Box>

            <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
                size="large"
              >
                Save Changes
              </Button>
            </Box>
          </Paper>
        </Box>

        <Paper elevation={1} sx={{ p: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <SecurityIcon sx={{ mr: 1, color: "primary.main" }} />
            <Typography variant="h6">Privacy Settings</Typography>
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={gdprConsent}
                onChange={handleGdprConsentChange}
                name="gdprConsent"
                color="primary"
              />
            }
            label="I consent to the use of cookies and data processing"
          />
        </Paper>
      </Paper>
    </Container>
  );
};
