import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import Upload from "../components/upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

const steps = ["Select File", "Upload", "Process"];

const UploadPage: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<
    "idle" | "success" | "error"
  >("idle");
  const navigate = useNavigate();

  const handleUploadComplete = (success: boolean, uploadID: number) => {
    setActiveStep(2);
    setUploadStatus(success ? "success" : "error");
    navigate(`/book/${uploadID}`);
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom textAlign="center">
          Upload Your Book
        </Typography>
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <Box textAlign="center" mb={3}>
              <CloudUploadIcon
                sx={{ fontSize: 60, color: "primary.main", mb: 2 }}
              />
              <Typography variant="h6" gutterBottom>
                Drag and drop your book file here or click to browse
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Supported formats: PDF, EPUB
              </Typography>
            </Box>
          )}

          <Upload
            onUploadStart={() => setActiveStep(1)}
            onUploadComplete={handleUploadComplete}
          />

          {uploadStatus === "success" && (
            <Box textAlign="center" mt={3}>
              <CheckCircleOutlineIcon
                sx={{ fontSize: 60, color: "success.main", mb: 2 }}
              />
              <Typography variant="h6" gutterBottom>
                Upload Successful!
              </Typography>
            </Box>
          )}

          {uploadStatus === "error" && (
            <Box textAlign="center" mt={3}>
              <ErrorOutlineIcon
                sx={{ fontSize: 60, color: "error.main", mb: 2 }}
              />
              <Typography variant="h6" gutterBottom>
                Upload Failed
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => setActiveStep(0)}
              >
                Try Again
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default UploadPage;
