import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Backend } from "../lib/backend";
import { Book, Chapter } from "../lib/definitions";
import {
  IconButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Paper,
  CircularProgress,
  Tooltip,
  Switch,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import theme from "../components/theme";
import BookCard from "../components/BookCard";
import IconButtonBar from "../components/IconButtonBar";
import BookStepper from "../components/BookStepper";
import CustomSnackbar from "../components/CustomSnackbar";
import { ResetIcon } from "@radix-ui/react-icons";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const backend = new Backend();

interface ChapterWithDragId extends Chapter {
  dragId: string;
}

export default function BookPreview() {
  const { bookId } = useParams<{ bookId: string }>();
  const navigate = useNavigate();
  const [book, setBook] = useState<Book | null>(null);
  const [chapters, setChapters] = useState<ChapterWithDragId[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedChapter, setExpandedChapter] = useState<string | false>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [resetTriggered, setResetTriggered] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  useEffect(() => {
    if (bookId) {
      setLoading(true);
      backend
        .fetchBook(parseInt(bookId))
        .then((fetchedBook) => {
          setBook(fetchedBook);
          setChapters(
            fetchedBook.Chapters.map((chapter) => ({
              ...chapter,
              dragId: `chapter-${chapter.ID}`,
            })).sort((a, b) => a.PositionInBook - b.PositionInBook)
          );
        })
        .catch((err) => setError("Failed to load book"))
        .finally(() => setLoading(false));
    }
  }, [bookId, resetTriggered]);

  const handleChapterChange = (
    chapterId: number,
    field: keyof Chapter,
    value: any
  ) => {
    setChapters((prevChapters) =>
      prevChapters.map((ch) =>
        ch.ID === chapterId ? { ...ch, [field]: value } : ch
      )
    );
  };

  const handleSave = async () => {
    if (!book) return;
    try {
      const updatedChapters = chapters.map((chapter, index) => ({
        ...chapter,
        PositionInBook: index,
      }));
      const updatedBook = { ...book, Chapters: updatedChapters };
      setBook(await backend.updateBook(updatedBook));
      setSnackbarMessage("Book saved successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error(error);
      setSnackbarMessage("Failed to save book");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleReset = async () => {
    if (book) {
      const updatedBook = await backend.resetBook(book.ID);
      setBook(updatedBook);
      setResetTriggered(true);
    }
  };

  const handleForward = async () => {
    if (book) {
      await handleSave();
      navigate(`/voice/${book.ID}`);
    }
  };

  const handleAccordionChange =
    (dragId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedChapter(isExpanded ? dragId : false);
    };

  const truncateContent = (content: string, maxLength: number = 500) => {
    if (content.length <= maxLength) return content;
    return content.slice(0, maxLength) + "...";
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newChapters = Array.from(chapters);
    const [reorderedItem] = newChapters.splice(result.source.index, 1);
    newChapters.splice(result.destination.index, 0, reorderedItem);

    const updatedChapters = newChapters.map((chapter, index) => ({
      ...chapter,
      PositionInBook: index,
    }));

    setChapters(updatedChapters);
  };

  const calculateApproximateLength = useMemo(() => {
    if (!chapters) return 0;
    const totalCharacters = chapters
      .filter((chapter) => chapter.IsEnabled)
      .reduce((sum, chapter) => sum + chapter.Content.length, 0);
    return totalCharacters / 62000; // 62,000 characters per hour
  }, [chapters]);

  const formatLength = (hours: number) => {
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return `${wholeHours}h ${minutes}m`;
  };

  const truncateTitle = (title: string, maxLength: number = 50) => {
    if (title.length <= maxLength) return title;
    return title.slice(0, maxLength) + "...";
  };

  const [hoveredChapters, setHoveredChapters] = useState<string[]>([]); // State to track hovered chapters

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!book) return <Typography>No book found</Typography>;

  return (
    <Container maxWidth="md" sx={{ paddingBottom: "100px" }}>
      <BookStepper bookId={bookId || ""} currentStep="book" />
      <BookCard book={book} />
      <Paper
        elevation={3}
        sx={{ p: 4, mt: 4, mb: 4, borderRadius: 2, overflow: "hidden" }}
      >
        <Box display="flex" justifyContent="center" mb={4}>
          <Typography textAlign="center" variant="h4" gutterBottom>
            Adjust the chapters to your liking.
          </Typography>
        </Box>
        <Box sx={{ margin: 8, justifyContent: "center" }}>
          <Box
            sx={{
              p: 2,
              backgroundColor: "grey.50",
              border: "1px solid",
              borderColor: "grey.200",
              borderRadius: 2,
            }}
          >
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: "bold" }}>
              Tips for customizing your book:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 32 }}>
                  <FiberManualRecordIcon
                    sx={{ fontSize: 6, color: "text.secondary" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="review chapters and edit their titles if needed"
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.secondary",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 32 }}>
                  <FiberManualRecordIcon
                    sx={{ fontSize: 6, color: "text.secondary" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="toggle off standard sections like Table of Contents or Acknowledgements if not required"
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.secondary",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 32 }}>
                  <FiberManualRecordIcon
                    sx={{ fontSize: 6, color: "text.secondary" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="drag and drop chapters to rearrange their order"
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.secondary",
                  }}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mx={8}
          mb={4}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Tooltip title="Reset to default order">
              <IconButton
                size="large"
                onClick={handleReset}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  boxShadow: 1,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                  },
                }}
              >
                <ResetIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            bgcolor={theme.palette.background.paper}
            p={1}
            borderRadius={1}
            border={`1px solid ${theme.palette.divider}`}
          >
            <AccessTimeIcon
              sx={{ mr: 1, color: theme.palette.text.secondary }}
            />
            <Typography variant="body2" color="text.secondary">
              Approx. Length:{" "}
              <Box component="span" fontWeight="bold">
                {formatLength(calculateApproximateLength)}
              </Box>
            </Typography>
          </Box>
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="chapters">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {chapters.map((chapter, index) => {
                  const isHovered = hoveredChapters.includes(chapter.dragId); // Check if the chapter is hovered
                  return (
                    <Draggable
                      key={chapter.dragId}
                      draggableId={chapter.dragId}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor: snapshot.isDragging
                              ? theme.palette.action.hover
                              : "transparent",
                            marginBottom: "16px", // Add space between items
                          }}
                          onMouseEnter={() => {
                            setHoveredChapters((prev) => [
                              ...prev,
                              chapter.dragId,
                            ]);
                          }} // Add to hovered state
                          onMouseLeave={() => {
                            setHoveredChapters((prev) =>
                              prev.filter((id) => id !== chapter.dragId)
                            );
                          }} // Remove from hovered state
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Accordion
                              expanded={expandedChapter === chapter.dragId}
                              onChange={handleAccordionChange(chapter.dragId)}
                              sx={{
                                flexGrow: 1,
                                "& .MuiAccordionSummary-root": {
                                  minHeight: "64px", // Increase the height of the summary
                                },
                              }}
                            >
                              <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                sx={{
                                  "& .MuiAccordionSummary-content": {
                                    margin: "12px 0", // Add vertical padding within the summary
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexGrow: 1,
                                      overflow: "hidden",
                                    }}
                                  >
                                    <Box
                                      {...provided.dragHandleProps}
                                      sx={{ mr: 2, cursor: "grab" }}
                                    >
                                      <DragIndicatorIcon />
                                    </Box>
                                    <Switch
                                      sx={{
                                        color: theme.palette.primary.main,
                                        mr: 2,
                                        "&.Mui-checked": {
                                          color: theme.palette.primary.main,
                                        },
                                      }}
                                      checked={chapter.IsEnabled}
                                      onChange={(e) =>
                                        handleChapterChange(
                                          chapter.ID,
                                          "IsEnabled",
                                          e.target.checked
                                        )
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontWeight: "bold",
                                        flexGrow: 1,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {truncateTitle(chapter.Title)}
                                    </Typography>
                                  </Box>
                                  <Tooltip title="Edit Chapter">
                                    <IconButton
                                      component={Link}
                                      to={`/chapter/${book.ID}/${chapter.ID}`}
                                      onClick={(e) => e.stopPropagation()}
                                      size="large"
                                      sx={{
                                        color: theme.palette.primary.main,
                                        opacity: isHovered ? 1 : 0.8, // Change opacity based on hover
                                        transition: "opacity 0.3s",
                                        "&:hover": {
                                          backgroundColor:
                                            theme.palette.primary.light,
                                        },
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {truncateContent(chapter.Content)}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IconButtonBar
          left={
            <Tooltip title="Back">
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: 1,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                  },
                  padding: 2,
                }}
                size="large"
              >
                <ArrowBackIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          }
          middle={
            <Tooltip title="Save">
              <IconButton
                onClick={handleSave}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  padding: 2,
                }}
                size="large"
              >
                <SaveIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          }
          right={
            <Tooltip title="Select Voice">
              <IconButton
                onClick={handleForward}
                sx={{
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: 1,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                  },
                  padding: 2,
                }}
                size="large"
              >
                <ArrowForwardIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          }
          price={book.Price}
        />
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Container>
  );
}
