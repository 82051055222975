import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, CircularProgress, Button } from "@mui/material";
import { Backend } from "../lib/backend";

const backend = new Backend();

const CheckoutComplete: React.FC = () => {
  const [status, setStatus] = useState<
    "loading" | "success" | "cancelled" | "error"
  >("loading");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const sessionId = params.get("session_id");
      const orderId = params.get("order_id");

      if (!sessionId) {
        setStatus("error");
        return;
      }

      try {
        let result;
        if (orderId) {
          result = await backend.verifyOrderPayment(sessionId, orderId);
        } else {
          result = await backend.verifySubscriptionPayment(sessionId);
        }
        setStatus(result.status === "paid" ? "success" : "cancelled");
      } catch (error) {
        console.error("Error verifying payment:", error);
        setStatus("error");
      }
    };

    verifyPayment().then((r) => r);
  }, [location]);

  const renderContent = () => {
    switch (status) {
      case "loading":
        return <CircularProgress />;
      case "success":
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Payment Successful!
            </Typography>
            <Typography variant="body1">
              Thank you for your purchase. You can now access your audiobook.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/books")}
              sx={{ mt: 2 }}
            >
              Go to My Books
            </Button>
          </>
        );
      case "cancelled":
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Payment Cancelled
            </Typography>
            <Typography variant="body1">
              Your payment was cancelled. If you still want to make a purchase,
              please try again.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              sx={{ mt: 2 }}
            >
              Back to Subscription Selection
            </Button>
          </>
        );
      case "error":
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Error
            </Typography>
            <Typography variant="body1">
              There was an error processing your payment. Please try again
              later.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/books")}
              sx={{ mt: 2 }}
            >
              Go to My Books
            </Button>
          </>
        );
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      {renderContent()}
    </Box>
  );
};

export default CheckoutComplete;
