import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  CircularProgress,
  Button,
  Container,
  Paper,
  Divider,
  useTheme,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Backend } from "../lib/backend";
import { PaymentOption } from "../lib/definitions";
import { useUser } from "../lib/UserContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
const backend = new Backend();

const Checkout: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [plan, setPlan] = useState<PaymentOption | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { user, isAuthenticated } = useUser();

  const searchParams = new URLSearchParams(location.search);
  const planType = searchParams.get("planType");

  useEffect(() => {
    if (!isAuthenticated) {
      const currentPath = location.pathname + location.search;
      navigate(`/login?redirect=${encodeURIComponent(currentPath)}`);
      return;
    }

    if (user?.SubscriptionIsActive) {
      navigate("/");
      return;
    }

    const initiateCheckout = async () => {
      if (!planType) {
        setError("Missing required parameters");
        setLoading(false);
        return;
      }

      try {
        const [fetchedPlans, checkoutSession] = await Promise.all([
          backend.getPaymentOptions(undefined),
          backend.createCheckoutSession(planType, undefined),
        ]);

        setPlan(fetchedPlans.find((p) => p.Type === planType) || null);
        setClientSecret(checkoutSession.clientSecret);
      } catch (err) {
        console.error("Checkout error:", err);
        setError("Failed to initiate checkout. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    initiateCheckout();
  }, [
    location.search,
    planType,
    isAuthenticated,
    navigate,
    location.pathname,
    user?.SubscriptionIsActive,
  ]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 4, mt: 4, textAlign: "center" }}>
          <Typography variant="h5" color="error" gutterBottom>
            {error}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            sx={{ mt: 2 }}
          >
            Go Back
          </Button>
        </Paper>
      </Container>
    );
  }

  if (!clientSecret || !plan) {
    return null;
  }

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        Complete Your Purchase
      </Typography>
      <Paper
        elevation={2}
        sx={{
          bgcolor: theme.palette.background.paper,
          height: "100%",
          borderRadius: 4,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Divider sx={{ mb: 2 }} />
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </Paper>
    </Container>
  );
};

export default Checkout;
