import axios, { AxiosInstance } from "axios";
import {
  Book,
  Chapter,
  Order,
  PaymentOption,
  SubscriptionDetails,
  User,
} from "./definitions";

// Add this interface
interface ContactFormSubmission {
  subject: string;
  messageType: string;
  message: string;
  email: string;
  name: string;
}

export class Backend {
  async listBooks(): Promise<Book[]> {
    const response = await this.client.get<Book[]>("/api/v1/books");

    return response.data;
  }

  async fetchBook(bookID: number): Promise<Book> {
    const response = await this.client.get<Book>(`/api/v1/books/${bookID}`);

    return response.data;
  }

  async resetBook(bookID: number): Promise<Book> {
    const response = await this.client.post<Book>(
      `/api/v1/books/${bookID}/reset`
    );

    return response.data;
  }

  async getOrders(): Promise<Order[]> {
    const response = await this.client.get<Order[]>("/api/v1/orders");

    return response.data;
  }

  async fetchUserInfo(): Promise<User> {
    const response = await this.client.get<User>("/api/v1/me");

    return response.data;
  }

  async fetchChapter(chapterID: number): Promise<Chapter> {
    const response = await this.client.get<Chapter>(
      `/api/v1/chapters/${chapterID}`
    );
    return response.data;
  }

  async updateBook(book: Book): Promise<Book> {
    const response = await this.client.put<Book>(
      `/api/v1/books/${book.ID}`,
      book
    );

    return response.data;
  }

  async updateChapter(chapter: Chapter): Promise<void> {
    const response = await this.client.put<void>(
      `/api/v1/chapters/${chapter.ID}`,
      chapter,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return response.data;
  }

  async narrateBook(bookID: number): Promise<Book> {
    const response = await this.client.post<Book>(
      `/api/v1/books/${bookID}/narrate`
    );

    return response.data;
  }

  async saveVoice(
    bookID: number,
    voice: string,
    outputFormat: string
  ): Promise<Book> {
    const response = await this.client.post<Book>(
      `/api/v1/books/${bookID}/voice`,
      { voice, outputFormat },
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return response.data;
  }

  async uploadFile(file: File): Promise<Book> {
    const formData = new FormData();
    formData.append("book", file);

    const response = await this.client.post<Book>("/api/v1/upload", formData);

    return response.data;
  }

  async login(email: string, password: string): Promise<void> {
    await this.client.post(
      "/login",
      { email, password },
      { withCredentials: true }
    );
  }

  async logout(): Promise<void> {
    await this.client.post("/logout", {}, { withCredentials: true });
  }

  async register(
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ): Promise<void> {
    await this.client.post("/register", {
      email,
      password,
      firstName,
      lastName,
    });
  }

  async deleteBook(bookId: number): Promise<void> {
    const response = await this.client.delete(`/api/v1/books/${bookId}`);

    if (response.status !== 200) {
      throw new Error("Failed to delete book");
    }
  }

  async listVoices(): Promise<string[]> {
    const response = await this.client.get<string[]>("/api/v1/voices");

    return response.data;
  }

  async getPaymentOptions(
    uploadID: number | undefined
  ): Promise<PaymentOption[]> {
    const endpoint = uploadID
      ? `/api/v1/payment-options/${uploadID}`
      : "/api/v1/subscriptions";
    const response = await this.client.get<PaymentOption[]>(endpoint);
    return response.data;
  }

  async createCheckoutSession(
    planType: string,
    uploadID: number | undefined
  ): Promise<{ clientSecret: string }> {
    const response = await this.client.post<{ clientSecret: string }>(
      "/api/v1/create-checkout-session",
      {
        planType,
        uploadID,
      }
    );
    return response.data;
  }

  async updateUserProfile(data: {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
  }) {
    const response = await this.client.put("/api/v1/user/profile", data);
    return response.data;
  }

  async verifyOrderPayment(
    sessionId: string,
    orderId: string
  ): Promise<{ status: "paid" | "unpaid" }> {
    const response = await this.client.get<{
      status: "paid" | "unpaid";
    }>(`/api/v1/verify-order-payment/${orderId}/${sessionId}`);
    return response.data;
  }

  async verifySubscriptionPayment(
    sessionId: string
  ): Promise<{ status: "paid" | "unpaid" }> {
    const response = await this.client.get<{
      status: "paid" | "unpaid";
    }>(`/api/v1/verify-payment/${sessionId}`);
    return response.data;
  }

  async googleOAuth(credential: string): Promise<void> {
    await this.client.post(
      "/auth/google/callback",
      {
        credential,
      },
      {
        withCredentials: true,
      }
    );
  }

  async fetchSubscriptionDetails(): Promise<SubscriptionDetails> {
    const response = await this.client.get<SubscriptionDetails>(
      "/api/v1/subscription"
    );
    return response.data;
  }

  async updateGdprConsent(consentGiven: boolean): Promise<void> {
    await this.client.put("/api/v1/user/gdpr-consent", { consentGiven });
  }

  async submitContactForm(data: ContactFormSubmission): Promise<void> {
    const response = await this.client.post("/api/v1/contact", data);
    if (response.status !== 201) {
      throw new Error("Failed to submit contact form");
    }
  }

  async requestPasswordReset(email: string): Promise<void> {
    const response = await this.client.post("/api/v1/forgot-password", {
      email,
    });
    if (response.status !== 200) {
      throw new Error("Failed to send reset email");
    }
  }

  async resetPassword(token: string, newPassword: string): Promise<void> {
    const response = await this.client.post("/api/v1/reset-password", {
      token,
      newPassword,
    });

    if (response.status !== 200) {
      throw new Error("Failed to reset password");
    }
  }

  private client: AxiosInstance;

  constructor() {
    const baseUrl = this.getBaseUrl();
    this.client = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });
  }

  getBaseUrl(): string {
    const port = process.env.REACT_APP_BASE_PORT || "8080";
    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost";
    return `${baseUrl}:${port}`;
  }
}
