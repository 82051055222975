import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4e6b97",
    },
    background: {
      default: "#fffbf4",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: "primary",
      },
    },
  },
});

export default theme;
