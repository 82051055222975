import React from "react";
import { Box, Container, Typography, Link, Stack, Paper } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: "auto",
      }}
    >
      <Paper elevation={0} sx={{ py: 2, px: 2 }}>
        <Container maxWidth="lg">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="body2">
              © {new Date().getFullYear()} {process.env.REACT_APP_COMPANY_NAME}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Link color="inherit" href="/contact" variant="body2">
                Contact Us
              </Link>
              <Link color="inherit" href="/privacy-policy" variant="body2">
                Privacy Policy
              </Link>
              <Link
                color="inherit"
                href="/terms-and-conditions"
                variant="body2"
              >
                Terms of Service
              </Link>
            </Stack>
          </Stack>
        </Container>
      </Paper>
    </Box>
  );
};

export default Footer;
