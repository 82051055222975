import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import BookPreview from "./pages/BookPreview";
import Home from "./pages/Home";
import BookList from "./pages/BookList";
import VoiceSelection from "./pages/VoiceSelection";
import VoiceList from "./pages/VoiceList";
import SubscriptionSelection from "./pages/PaymentSelection";
import CheckoutComplete from "./pages/CheckoutComplete";
import Checkout from "./pages/Checkout";
import ChapterPreview from "./pages/ChapterPreview";
import FreeCheckout from "./pages/FreeCheckout";
import Subscriptions from "./pages/Subscriptions";
import CheckoutSubscription from "./pages/CheckoutSubscription";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import { ProfilePage } from "./pages/ProfilePage";
import { MySubscriptionPage } from "./pages/MySubscriptionPage";
import UploadPage from "./pages/Upload";
import Orders from "./pages/Orders";
import ContactForm from "./pages/ContactForm";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "register",
        element: <Registration />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "book/:bookId",
        element: <BookPreview />,
      },
      {
        path: "books",
        element: <BookList />,
      },
      {
        path: "voice/:bookId",
        element: <VoiceSelection />,
      },
      {
        path: "voices",
        element: <VoiceList />,
      },
      {
        path: "subscription/:uploadID",
        element: <SubscriptionSelection />,
      },
      {
        path: "checkout-complete",
        element: <CheckoutComplete />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "chapter/:bookId/:chapterId",
        element: <ChapterPreview />,
      },
      {
        path: "checkout-free/:uploadID",
        element: <FreeCheckout />,
      },
      {
        path: "subscriptions",
        element: <Subscriptions />,
      },
      {
        path: "checkout-subscription",
        element: <CheckoutSubscription />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "my-subscription",
        element: <MySubscriptionPage />,
      },
      {
        path: "upload",
        element: <UploadPage />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
      {
        path: "contact",
        element: <ContactForm />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
    // errorElement: <ErrorPage />,
    // children: [
    //     {
    //         index: true,
    //         element: <HomePage />,
    //     },
    //     {
    //         path: 'products',
    //         element: <ProductsPage />,
    //     },
    //     {
    //         path: 'products/:id',
    //         element: <ProductPage />,
    //     },
    //     {
    //         path: 'admin',
    //         element: (
    //             <Suspense
    //                 fallback={<div className="text-center p-5 text-xl text-slate-900">Loading...</div>}
    //             >
    //                 <AdminPage />
    //             </Suspense>
    //         ),
    //     },
    // ],
  },
]);

export function Routes() {
  return <RouterProvider router={router} />;
}
