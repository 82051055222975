import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";
import { UserProvider } from "./lib/UserContext";
import theme from "./components/theme";
import { Navigation } from "./components/menubar";
import { CookieConsentBar } from "./components/CookieConsentBar";
import { Outlet } from "react-router-dom";
import Footer from "./components/footer";

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh", // Ensure the Box takes at least the full viewport height
            }}
          >
            <Navigation />
            <Box
              component="main"
              sx={{
                flexGrow: 1, // This will make the main content area grow to push the footer down
                mt: 8, // Add top margin to account for the fixed Navigation
                mb: 4, // Add some bottom margin
                px: 4, // Add horizontal padding
              }}
            >
              <Outlet />
              <CookieConsentBar />
            </Box>
            <Footer />
          </Box>
        </UserProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
