import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Backend } from "../lib/backend";
import { PaymentOption } from "../lib/definitions";
import { Box, Container, IconButton, Tooltip, Typography } from "@mui/material";
import PaymentCards from "../components/PaymentCards";
import theme from "../components/theme";
import IconButtonBar from "../components/IconButtonBar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const backend = new Backend();

export default function Subscriptions() {
  const [subscriptionOptions, setSubscriptionOptions] = useState<
    PaymentOption[]
  >([]);
  const [selectedPlan, setSelectedPlan] = useState<PaymentOption | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSubscriptionOptions() {
      try {
        // Fetch subscription options without a specific uploadID
        const options = await backend.getPaymentOptions(0);
        setSubscriptionOptions(options);
      } catch (err) {
        setError("Failed to fetch subscription options");
      } finally {
        setLoading(false);
      }
    }

    fetchSubscriptionOptions();
  }, []);

  const handleCheckout = () => {
    if (selectedPlan) {
      navigate(`/checkout-subscription?planType=${selectedPlan.Type}`);
    }
  };

  if (loading) return <div>Loading subscription options...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container maxWidth="md">
      <Box textAlign="center" my={4} sx={{ paddingBottom: "50px" }}>
        <Typography variant="h4" gutterBottom>
          Choose Your Plan
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Select the plan that works best for you
        </Typography>
      </Box>
      <PaymentCards plans={subscriptionOptions} onSelect={setSelectedPlan} />
      <IconButtonBar
        price={selectedPlan?.Price ?? null}
        left={
          <Tooltip title="Back">
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
        middle={null}
        right={
          <Tooltip title="Subscribe">
            <span>
              <IconButton
                onClick={handleCheckout}
                disabled={!selectedPlan}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: theme.palette.action.disabledBackground,
                    color: theme.palette.action.disabled,
                  },
                  padding: 2,
                }}
                size="large"
              >
                <ShoppingCartIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        }
      />
    </Container>
  );
}
