import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export interface PasswordRequirement {
  label: string;
  validator: (password: string) => boolean;
}

export const passwordRequirements: PasswordRequirement[] = [
  {
    label: "At least 8 characters long",
    validator: (password) => password.length >= 8,
  },
  {
    label: "Contains at least one uppercase letter",
    validator: (password) => /[A-Z]/.test(password),
  },
  {
    label: "Contains at least one number",
    validator: (password) => /[0-9]/.test(password),
  },
  {
    label: "Contains at least one special character",
    validator: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
  },
];

interface PasswordRequirementsProps {
  password: string;
}

export const isPasswordValid = (password: string) => {
  return passwordRequirements.every((req) => req.validator(password));
};

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  password,
}) => {
  return (
    <List dense sx={{ mt: 2, bgcolor: "background.paper" }}>
      {passwordRequirements.map((req, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            {req.validator(password) ? (
              <CheckCircleIcon color="success" fontSize="small" />
            ) : (
              <CancelIcon color="error" fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={req.label}
            primaryTypographyProps={{
              variant: "body2",
              color: req.validator(password)
                ? "text.primary"
                : "text.secondary",
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default PasswordRequirements;
