import React from "react";
import { useUser } from "../lib/UserContext";
import {
  Box,
  Typography,
  Container,
  Paper,
  LinearProgress,
} from "@mui/material";
import { formatNumber } from "../utils/formatNumber";

export const MySubscriptionPage: React.FC = () => {
  const { user } = useUser();

  if (!user) {
    return <Typography>Loading...</Typography>;
  }

  const percentageUsed = (user.QuotaUsed / user.QuotaTotal) * 100;

  const subscriptionName = user.SubscriptionName;
  const nextPaymentDate = user.NextPaymentDate
    ? new Date(user.NextPaymentDate).toLocaleDateString()
    : "N/A";

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom>
          My Subscription
        </Typography>
        <Box my={3}>
          <Typography variant="h6">Current Plan: {subscriptionName}</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="subtitle1" gutterBottom>
            Characters Remaining
          </Typography>
          <LinearProgress
            variant="determinate"
            value={percentageUsed}
            sx={{ height: 10, borderRadius: 5 }}
          />
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Typography variant="body2">
              {formatNumber(user.QuotaUsed)} / {formatNumber(user.QuotaTotal)}{" "}
              characters left
            </Typography>
            <Typography variant="body2">
              {percentageUsed.toFixed(1)}% used
            </Typography>
          </Box>
        </Box>
        <Box my={3}>
          <Typography variant="subtitle1">
            Next Payment Due: {nextPaymentDate}
          </Typography>
        </Box>
        {/* <Box mt={4}>
          <Button variant="contained" color="primary">
            Upgrade Plan
          </Button>
        </Box> */}
      </Paper>
    </Container>
  );
};
