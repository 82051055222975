import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { Backend } from "./backend";
import { User } from "./definitions";
import { useNavigate } from "react-router-dom";

interface UserContextType {
  user: User | null;
  loading: boolean;
  error: Error | null;
  refetch: () => void;
  setUser: (user: User | null) => void;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  googleOAuth: (credential: string) => Promise<void>;
  logout: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

const backend = new Backend();

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const userData = await backend.fetchUserInfo();
        setUser(userData);
        setIsAuthenticated(userData.IsAuthenticated);
      } catch (error) {
        setError(
          error instanceof Error ? error : new Error("Failed to fetch user")
        );
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, [isAuthenticated]);

  const login = async (email: string, password: string) => {
    try {
      await backend.login(email, password);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    try {
      await backend.register(email, password, firstName, lastName);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Registration failed:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await backend.logout();
      setIsAuthenticated(false);
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
      throw error;
    }
  };

  const googleOAuth = async (credential: string) => {
    try {
      await backend.googleOAuth(credential);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Google OAuth failed:", error);
      throw error;
    }
  };

  const refetch = async () => {
    if (!isAuthenticated) {
      return;
    }
    setLoading(true);
    try {
      const userData = await backend.fetchUserInfo();
      setUser(userData);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to fetch user"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        error,
        refetch,
        setUser,
        isAuthenticated,
        login,
        logout,
        register,
        googleOAuth,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
