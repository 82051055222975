import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";

export type OutputFormat = "m4b" | "single_mp3" | "multiple_mp3s";

interface OutputFormatSelectProps {
  value: OutputFormat;
  onChange: (format: OutputFormat) => void;
}

const OutputFormatSelect: React.FC<OutputFormatSelectProps> = ({
  value,
  onChange,
}) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as OutputFormat);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <FormControl
        component={Paper}
        variant="outlined"
        sx={{
          minWidth: 300,
          "& .MuiOutlinedInput-root": {
            transition: "all 0.3s ease-in-out",
          },
        }}
      >
        <InputLabel id="output-format-select-label">Output Format</InputLabel>
        <Select
          labelId="output-format-select-label"
          id="output-format-select"
          value={value}
          label="Output Format"
          onChange={handleChange}
        >
          <MenuItem value="m4b">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AudiotrackIcon
                sx={{ mr: 1, color: theme.palette.primary.main }}
              />
              <Typography>M4B</Typography>
            </Box>
          </MenuItem>
          <MenuItem value="single_mp3">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AudiotrackIcon
                sx={{ mr: 1, color: theme.palette.secondary.main }}
              />
              <Typography>Single MP3</Typography>
            </Box>
          </MenuItem>
          {/* <MenuItem value="multiple_mp3s">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AudiotrackIcon sx={{ mr: 1, color: theme.palette.error.main }} />
              <Typography>Multiple MP3s</Typography>
            </Box>
          </MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
};

export default OutputFormatSelect;
